import { openDB } from 'idb';

const ObjectStore = {
    LOOKUP: 'ut_lookup',
    LOOKUP_PRODUCT: 'ut_lookup_product',
};

const Index = {
    LOOKUPS_BY_VEHICLE: 'lookupsByWorksheetVehicleId',
};

const READ_WRITE = 'readwrite';

const MANUFACTURER = 'MANUFACTURER';

let _db;
const getConnection = async () => {
    if (!_db) {
        _db = await openDB('ut', 1, {
            upgrade(db) {
                const lookupStore = db.createObjectStore(ObjectStore.LOOKUP, { keyPath: 'uuid' });
                lookupStore.createIndex(Index.LOOKUPS_BY_VEHICLE, 'vehicleId');

                db.createObjectStore(ObjectStore.LOOKUP_PRODUCT, { keyPath: ['uuid', 'partId'] });
            },
        });
    }

    return _db;
};

const saveLookups = async (lookup, lookupsProducts) => {
    const db = await getConnection();
    const tx = db.transaction([ObjectStore.LOOKUP, ObjectStore.LOOKUP_PRODUCT], READ_WRITE);
    const txArray = [];

    // Adding ut_lookups record
    txArray.push(tx.objectStore(ObjectStore.LOOKUP).put(lookup));

    // Adding ut_lookups_products
    const lookupProductStore = tx.objectStore(ObjectStore.LOOKUP_PRODUCT);
    lookupsProducts.forEach((item) => {
        txArray.push(lookupProductStore.put(item));
    });

    txArray.push(tx.done);
    return Promise.allSettled(txArray);
};

const generateTimeStamp = () => {
    const dateParts = new Intl.DateTimeFormat(undefined, {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        hour12: false,
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    }).formatToParts(Date.now());

    return `${dateParts[0].value}, ${dateParts[4].value} ${dateParts[2].value} ${dateParts[6].value} ${dateParts[8].value}:${dateParts[10].value}:${dateParts[12].value} ${dateParts[14].value}`;
};

export const saveCatalogLookUp = async ({ uuid, currentVehicle, lookupType, searchQuery, lookupSummary, lookupDetails, resultDetails }) => {
    const timeStamp = generateTimeStamp();

    const utLookup = {
        uuid,
        timeStamp,
        lookupType,
        searchQuery,
        vehicleID: currentVehicle?.id,
        vehicleBaseId: currentVehicle?.vehicleId,
        selectedManufacturer: lookupDetails.find((lookupDetail) => lookupDetail.detailType === MANUFACTURER)?.detailValue,
    };

    const utLookupProducts = [];

    resultDetails.forEach((result) => {
        utLookupProducts.push({
            uuid,
            timeStamp,
            legacyPartId: null,
            partId: `${result.key.line}|${result.key.item}`,
            partTypeId: result.key.partTypeId,
            parentPartTypeId: lookupSummary.find((summary) => summary.keypartTypeId === result.key.partTypeId)?.parentPartTypeId,
            localQuantityOnHand: result.localQOH,
            stocking: result.stockingItem,
        });
    });

    await saveLookups(utLookup, utLookupProducts);
};

export const saveRelatedProductsLookup = async (relatedProducts) => {
    if (relatedProducts.length === 0) return;

    const lookup = {
        uuid: relatedProducts[0].uuid.uuid,
        timeStamp: relatedProducts[0].uuid.timeStamp,
        lookupType: relatedProducts[0].lookupType,
        vehicleID: relatedProducts[0].vehicleId,
        vehicleBaseId: relatedProducts[0].baseVehicleId,
        searchQuery: null,
        selectedManufacturer: null,
    };

    const lookupsProducts = relatedProducts.map((product) => ({
        uuid: product.uuid.uuid,
        partId: product.partId,
        legacyPartId: null,
        partTypeId: product.partTypeId,
        parentPartTypeId: product.parentPartTypeId,
        localQuantityOnHand: product.localQuantityOnHand,
        stocking: product.stocking,
    }));

    await saveLookups(lookup, lookupsProducts);
};

export const completeCatalogLookupEvent = async (uuid) => {
    const db = await getConnection();
    const tx = db.transaction([ObjectStore.LOOKUP, ObjectStore.LOOKUP_PRODUCT], 'readwrite');

    await Promise.allSettled([
        tx.objectStore(ObjectStore.LOOKUP).delete(uuid),
        (async () => {
            const store = tx.objectStore(ObjectStore.LOOKUP_PRODUCT);
            for await (const cursor of store) {
                if (cursor.value.uuid === uuid) {
                    store.delete(cursor.primaryKey);
                }
            }
        })(),
        tx.done,
    ]);
};

export const clearAll = async () => {
    const db = await getConnection();
    const tx = db.transaction([ObjectStore.LOOKUP, ObjectStore.LOOKUP_PRODUCT], 'readwrite');

    await Promise.allSettled([tx.objectStore(ObjectStore.LOOKUP_PRODUCT).clear(), tx.objectStore(ObjectStore.LOOKUP).clear(), tx.done]);
};
