export const LOOKUP_TYPE = {
    TEXT: 'TEXT',
    PART_NO: 'PART_NO',
    DRILL_DOWN: 'DRILL_DOWN',
    RELEVANT: 'RELEVANT',
    INTCHANGE: 'INTCHANGE',
    QUICK_ADD: 'QUICK_ADD',
    REL_ITEM: 'REL_ITEM',
    JOB: 'JOB',
    BRAND: 'BRAND',
    PROMO: 'PROMO',
    OTHER: 'OTHER',
};
