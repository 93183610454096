import { throttle } from 'lodash';
import analytics from 'fcoModules/analytics';
import { mapState } from 'vuex';
import { CatalogTabOrigins, LookupOrigin } from '@/common/constants/ordering';
import axios from 'axios';
import featureFlagsMixin from '@/common/mixins/featureFlagsMixin';
import forceCarMixin from '@/common/mixins/forceCarMixin';
import helpersMixin from '@/common/mixins/helpersMixin';
import * as partTypeService from '../../../common/services/partTypeService';
import { fcoUrl } from '../../../fcoModules/utilities';

const defaultMaxPartTypesSelected = 5;
const errorDelayTime = 5000;
const packageStringValue = 'PACKAGEJOB';
const plpPath = fcoUrl('/parttype/mini/getparts.html');

const getIsPackageJob = (item) => Object.prototype.hasOwnProperty.call(item, 'partTypes');

const getItemId = (item) => {
    if (getIsPackageJob(item)) return item.id;
    return item.partTypeId;
};

// format selected part type for BE endpoint
const denormalizePartType = (item) => {
    const { partTypeId, id, parentPartTypeId: parentId, platformId, lookupPartTypeId, gaTabOrigin, partTypeName } = item;
    const denormalizedItem = { parentId, partTypeId, platformId, lookupPartTypeId, gaTabOrigin, partTypeName };

    if (getIsPackageJob(item)) {
        denormalizedItem.partTypeId = id;
        denormalizedItem.parentId = packageStringValue;
    }

    return denormalizedItem;
};

export default {
    mixins: [featureFlagsMixin, forceCarMixin, helpersMixin],
    computed: {
        ...mapState({
            requests: (state) => state.requests,
            isSPA: (state) => state.isSPA,
            user: ({ user }) => user,
            currentShop: ({ currentShop }) => currentShop,
        }),
        ...mapState('vehicleSelector', {
            vehicleRequest: (state) => state.requests.getCurrentVehicle,
            vehicle: (state) => state.currentVehicle,
        }),
        searchQuery() {
            if (!this.isSPA) {
                return new URLSearchParams(window.location.search).get('query');
            }
            return this.$route.query.query;
        },
    },
    methods: {
        $_partTypeMixin_getItemId: (item) => getItemId(item),
        // selecting partType validation
        $_partTypeMixin_isValidSelection(selectedList, item, maxLimitCnt = defaultMaxPartTypesSelected) {
            const itemId = getItemId(item);
            // duplication check
            if (selectedList.some((selectedItem) => getItemId(selectedItem) === itemId)) {
                return false;
            }

            // max part types exceeded
            const maxErrorMsg = maxLimitCnt === 7 ? this.fcoM('rs.partSelection.maxPartError7', 'You can only select up to 7 parts at a time.') : this.fcoM('rs.partSelection.maxPartError5', 'You can only select up to 5 parts at a time.');
            if (selectedList.length >= maxLimitCnt) {
                this.showMaxTypesError(maxErrorMsg);
                return false;
            }

            // Kits must be sourced independently
            if (selectedList.length > 0 && (item.kits || selectedList.filter((obj) => obj.kits).length > 0)) {
                this.showerrorKitsSourcingError(this.fcoM('rs.partSelection.errorKitsSourcing', 'Kits must be sourced independently'));
                return false;
            }
            return true;
        },

        // submit to get parts
        // step 1. prepare for payload data
        // step 2. check vehicle data if it requires vehicle data
        // step 3. move to PLP page
        async $_partTypeMixin_submitGetParts(partsList, origin = '') {
            if (partsList.length <= 0) return;

            let selectedList = [];
            // if is ent search and suggested part, the 'universal' prop should exist and is used to determine if this is an
            // application part type. Other areas expect applicationPartType prop so let's add it
            if (this.isEntSearchEnabled && origin === LookupOrigin.SUGGESTION && partsList.every((pt) => pt.hasOwnProperty('universal'))) {
                selectedList = partsList.map((pt) => ({ ...pt, applicationPartType: !pt.universal }));
            } else {
                selectedList = [...partsList];
            }

            let loading = this.$fcoLoading();
            const payload = selectedList.map(denormalizePartType);

            // add google analytics catalogLookup
            analytics({ event: 'catalogLookup', data: payload });

            try {
                const {
                    data: { vehicleRequired },
                } = await partTypeService.clearAndSelectPartType(payload);

                let redirect;
                const spaCatalogPath = this.getSpaCatalogRedirectPath(selectedList.some((item) => item.kits));

                if (this.searchQuery) {
                    redirect = { path: spaCatalogPath, query: { query: this.searchQuery, ptsearch: 1 } };
                } else {
                    redirect = spaCatalogPath;
                }

                if (!this.isSPA) {
                    if (this.isEntSearchEnabled && !selectedList.some((item) => item.kits)) {
                        if (this.searchQuery) {
                            redirect = this.fcoUrl(`/parttype/mini/v2/getParts.html?query=${encodeURIComponent(this.searchQuery)}&ptsearch=1`);
                        } else {
                            redirect = this.fcoUrl(`/parttype/mini/v2/getParts.html`);
                        }
                    } else {
                        redirect = this.searchQuery ? `${plpPath}?query=${this.searchQuery}&ptsearch=1` : plpPath;
                    }
                }

                if (vehicleRequired && !this.vehicle) {
                    // forceCar if vehicleRequired and has no current vehicle
                    loading.remove();
                    if (this.isEntSearchEnabled && (origin === LookupOrigin.SUGGESTION || origin === LookupOrigin.SUGGESTED_PART_MENU)) {
                        this.forceCar().then(async () => {
                            if (this.currentVehicle) {
                                loading = this.$fcoLoading();
                                const finalList = origin === LookupOrigin.SUGGESTION ? selectedList : partsList;

                                try {
                                    await this.$_partTypesMixin_getResolvedLeaf(finalList, origin);
                                } catch {
                                    loading.remove();
                                    return;
                                }

                                if (!this.isSPA) {
                                    window.location.href = redirect;
                                    return;
                                }
                                loading.remove();
                                this.$router.push(redirect);
                            }
                        });
                    } else if (this.isEntSearchEnabled && origin === LookupOrigin.CATALOG) {
                        this.forceCar().then(async () => {
                            if (this.currentVehicle) {
                                loading = this.$fcoLoading();

                                await this.handleCatalogPartSelection(selectedList, origin);

                                if (!this.isSPA) {
                                    window.location.href = redirect;
                                    return;
                                }
                                loading.remove();
                                this.$router.push(redirect);
                            }
                        });
                    } else {
                        this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'saveLookupBeforeRedirect', value: true });
                        this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'partsToSaveBeforeRedirect', value: selectedList });
                        this.$store.dispatch('vehicleSelector/forceCar', redirect);
                    }
                } else {
                    if (this.isEntSearchEnabled) {
                        // Need to get resolved part types for catalog part selections
                        if (origin === LookupOrigin.CATALOG) {
                            await this.handleCatalogPartSelection(selectedList, origin);
                        } else if (origin === LookupOrigin.SUGGESTED_PART_MENU || origin === LookupOrigin.SUGGESTION) {
                            const finalList = origin === LookupOrigin.SUGGESTION ? selectedList : partsList;

                            try {
                                await this.$_partTypesMixin_getResolvedLeaf(finalList, origin);
                            } catch {
                                loading.remove();
                                return;
                            }
                        } else {
                            this.$store.dispatch('partSelection/savePartLookup', {
                                partTypes: selectedList,
                                vehicle: this.vehicle,
                                origin: origin || null,
                            });
                        }
                    }
                    if (!this.isSPA) {
                        // move to next url
                        window.location.href = redirect;
                        return;
                    }
                    loading.remove();
                    this.$router.push(redirect);
                }
            } catch {
                loading.remove();
                this.$fcoToast.error(this.fcoM('rs.partSelection.errorSend', 'An error occurred while retrieving your parts. Please try again.'));
            }
        },
        // set focus
        $_partTypeMixin_setFocusByPositionIndex(refIdByIndex) {
            const targetObj = this.$refs[refIdByIndex];
            if (targetObj && targetObj.length > 0) {
                targetObj[0].focus();
            }
        },
        async $_partTypeMixin_getResolvedPartTypes(selectedPartTypesList, origin) {
            try {
                const { data } = await axios.post(this.fcoUrl('/parttype/node/v2/resolved'), {
                    vehicle: this.currentVehicle,
                    selectedPartTypes: selectedPartTypesList,
                });
                const formattedResolvedParts = data.results
                    .map((pt) => {
                        // if leaf is true then there will never be resolved part type nodes
                        if (!pt.leaf && pt.resolvedPartTypeNodes?.length) {
                            pt.resolvedPartTypeNodes.forEach((resolvedPt) => {
                                if (pt.selectedFacets) resolvedPt.selectedFacets = pt.selectedFacets;
                            });
                            return pt.resolvedPartTypeNodes;
                        }
                        return [pt];
                    })
                    .flat();
                this.$store.dispatch('partSelection/savePartLookup', {
                    partTypes: selectedPartTypesList,
                    resolvedPartTypes: formattedResolvedParts,
                    fullResolvedDetails: data.results,
                    vehicle: this.vehicle,
                    origin: origin || null,
                });
            } catch (error) {
                this.$fcoToast.error(this.fcoM('rs.getparts.partsRequestError', 'Error retrieving product data. Please try again.'));
                throw new Error(error);
            }
        },
        async $_partTypesMixin_getResolvedLeaf(selectedPartTypesList, origin) {
            const leafPartTypes = selectedPartTypesList.map((partType) => ({
                includeSpecifications: true,
                includeSiblings: true,
                selectedPartType: {
                    description: partType.description,
                    parentPartTypeId: partType.parentPartTypeId,
                    partTypeId: partType.partTypeId,
                    platformId: partType.platformId,
                    applicationPartType: partType.applicationPartType,
                },
            }));

            try {
                const { data } = await axios.post(this.fcoUrl('/parttype/node/resolve/leaf'), {
                    vehicle: this.currentVehicle,
                    leafPartTypes,
                });

                // need to get all siblings combined and remove duplicates
                const exclusionSet = new Set(selectedPartTypesList.map((pt) => pt.partTypeId));
                const map = new Map();
                const specificationPartTypes = [];

                data.forEach((obj) => {
                    obj.siblings.forEach((item) => {
                        if (!exclusionSet.has(item.partTypeId)) {
                            map.set(item.partTypeId, item);
                        }
                    });
                    if (obj.specifications.length) {
                        obj.specifications.forEach((spec) => {
                            specificationPartTypes.push({ ...spec, specForPartType: obj.partType.partTypeId });
                        });
                    }
                });

                const siblingsList = Array.from(map.values());

                this.$store.dispatch('partSelection/savePartLookup', {
                    partTypes: selectedPartTypesList,
                    siblingPartTypes: siblingsList,
                    vehicle: this.vehicle,
                    specificationPartTypes,
                    origin,
                });
            } catch (error) {
                this.$fcoToast.error(this.fcoM('rs.getparts.partsRequestError', 'Error retrieving product data. Please try again.'));
                throw new Error(error);
            }
        },
        async handleCatalogPartSelection(selectedList, origin) {
            // check if any selections are package jobs and if so need to get more usable data for part types in
            // the package job to use with resolved
            const partsFromPackageJobs = [];
            const packageJobsSelections = [];
            const nonPackageJobsSelections = [];
            selectedList.forEach((selection) => {
                if (selection.gaTabOrigin === CatalogTabOrigins.PACKAGE_JOBS) packageJobsSelections.push(selection);
                else nonPackageJobsSelections.push(selection);
            });

            try {
                await Promise.all(
                    packageJobsSelections.map(async (selection) => {
                        const { data: packageJobParts } = await axios.post(this.fcoUrl('/packagerest/packageJobPartTypes'), {
                            shopId: this.currentShop?.id,
                            packageJobIds: [selection.id],
                        });
                        partsFromPackageJobs.push(...packageJobParts);
                    })
                );
            } catch {
                this.$fcoToast.error(this.fcoM('rs.getparts.partsRequestError', 'Error retrieving product data. Please try again.'));
                return;
            }

            try {
                await this.$_partTypeMixin_getResolvedPartTypes([...nonPackageJobsSelections, ...partsFromPackageJobs], origin);
            } catch {
                // nothing needed, getResolvedPartTypes will throw a toast error.
            }
        },
        showMaxTypesError: throttle(function showError(msg) {
            this.$fcoToast.error(msg, { displayDuration: errorDelayTime });
        }, errorDelayTime),
        showerrorKitsSourcingError: throttle(function showError(msg) {
            this.$fcoToast.error(msg, { displayDuration: errorDelayTime });
        }, errorDelayTime),
    },
    mounted() {
        this.$store.dispatch('requestIfIdle', ['getCurrentShop', 'getFeatures', 'vehicleSelector/getCurrentVehicle']);
    },
};
