import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('vehicleSelector', ['forceUrl', 'currentVehicle']),
    },
    methods: {
        forceCar() {
            return new Promise((resolve) => {
                this.$store.dispatch('vehicleSelector/forceCar', true);
                const unwatch = this.$watch(
                    () => this.forceUrl && !this.currentVehicle,
                    () => {
                        resolve(this.$store.state.vehicleSelector.currentVehicle);
                        unwatch();
                    }
                );
            });
        },
    },
};
