<template>
    <div class="smart-product-search-container">
        <FcoTypeahead
            inputClass="fco-typeahead-input"
            :value="inputValue"
            :menu-class="'main-search-results'"
            :results="curResults"
            :resultsUseCategories="isEntSearchEnabled"
            :placeholder="placeholder"
            :map-result="({ displayName }) => displayName"
            :minChars="1"
            mode="custom"
            :maxResults="99"
            :showSearchDuringInput="true"
            :filterInput="cleanTerm"
            :menuOffset="[0, 0]"
            :disableAutoResultSelect="isEntSearchEnabled"
            ref="siteSearch"
            ignore-left-right-arrow-keypress
            @focus="$emit('focus')"
            @search="handleSearch"
            @input-no-debounce="handleActiveInput"
            @enter-press-before-search="quickEntered = true"
            @select="handleSelect"
        />
        <QuoteSelectionModal
            v-if="showNewQuotePrompt"
            @close="handleQuotePromptClose"
            @start-new-quote="startNewQuote(tempVehicle)"
            @use-existing-quote="addToExistingQuote(tempVehicle)"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { FcoTypeahead } from 'fco/src/vue/components/typeahead';
import axios from 'axios';
import featureFlagsMixin from '@/common/mixins/featureFlagsMixin';
import helpersMixin from '@/common/mixins/helpersMixin';
import { LookupOrigin } from '@/common/constants/ordering';
import partTypeSelectionMixin from '@/ordering/partTypeSelection/mixins/partTypeSelectionMixin';
import QuoteSelectionModal from './vehicleSelector/QuoteSelectionModal.vue';
import vsMixin from '../mixins/vsMixin';
import SignalsTrackingMixin from '../../ordering/common/mixins/signalsTrackingMixin';
import { clearAndSelectPartType } from '../services/partTypeService';
import { isRequestSettled, requestSettled } from '../store/request-status';
import analytics, { GTM } from '../../fcoModules/analytics';

// for cancelling axios requests
let source;
const typeAheadTypes = {
    PART_TYPE: 'PART_TYPE',
    VEHICLE: 'VEHICLE',
    CUSTOM_SEARCH_QUERY: 'CUSTOM_SEARCH_QUERY', // Used for non-enterprise search to add a user's custom search query to the list of results
};

export default {
    name: 'ProductSearch',
    mixins: [vsMixin, SignalsTrackingMixin, featureFlagsMixin, helpersMixin, partTypeSelectionMixin],
    data() {
        return {
            curResults: [],
            curPartsResults: [],
            curVehicleResults: [],
            curSearchTerm: '',
            quickEntered: false,
            vehicleRequiredPartsList: [],
            inputValue: '',
            showNewQuotePrompt: false,
            tempVehicle: null,
            keywordRedirect: null,
            partTypeRedirect: null,
            pendingPartType: null,
        };
    },
    components: { FcoTypeahead, QuoteSelectionModal },
    computed: {
        ...mapState(['requests', 'isSPA', 'currentShop']),
        ...mapState('vehicleSelector', {
            vehicleRequest: (state) => state.requests.getCurrentVehicle,
            currentVehicle: (state) => state.currentVehicle,
            partTypePendingForceCar: (state) => state.partTypePendingForceCar,
        }),
        vehicleIsSelected() {
            return !!this.currentVehicle?.vehicleId;
        },
        productsInCurrentQuote() {
            return this.$store.state.miniQuote.quoteDetails.totalItems > 0;
        },
        placeholder() {
            if (!isRequestSettled(this.requests.getFeatures)) return '';
            return this.isEntSearchEnabled ? this.fcoM('keywordsearch.entSearchDefault', 'Enter Product, Part #, or Brand') : this.fcoM('keywordsearch.default', 'Enter Part, Item #, or Brand');
        },
        cleanSearchTerm() {
            return this.cleanTerm(this.curSearchTerm).trim();
        },
    },
    methods: {
        startNewQuote(tempVehicle) {
            this.showNewQuotePrompt = false;
            this.handleVehicleSelect(tempVehicle);
        },
        addToExistingQuote(tempVehicle) {
            this.showNewQuotePrompt = false;
            this.handleVehicleSelect(tempVehicle, true);
        },
        handleQuotePromptClose() {
            // reset vehicle prompt data as user has cancelled out of selecting a vehicle
            this.tempVehicle = null;
            this.keywordRedirect = null;
            this.partTypeRedirect = null;
            this.showNewQuotePrompt = false;
        },
        handleActiveInput(term) {
            this.curSearchTerm = term;
        },
        handleSearch() {
            // abort any previous requests if they still exist
            if (source) {
                source.cancel();
            }

            const searchTerm = this.cleanSearchTerm;
            const { CancelToken } = axios;
            let searchUrl = '';
            if (this.isEntSearchEnabled) {
                searchUrl = this.fcoUrl(`/search/keyword/v3/typeahead?query=${encodeURIComponent(searchTerm)}&suggestionType=PART_TYPE`);
            } else {
                searchUrl = this.fcoUrl(`/search/keyword/typeahead?query=${encodeURIComponent(searchTerm)}&entSearchEnabled=false`);
            }
            source = CancelToken.source();

            axios
                .get(searchUrl, { cancelToken: source.token })
                .then(async ({ data }) => {
                    this.curResults = [];
                    this.tempVehicle = null;
                    this.keywordRedirect = null;
                    this.partTypeRedirect = null;
                    this.pendingPartType = null;
                    this.curPartsResults = [...data.partTypes];
                    this.curVehicleResults = [...data.vehicles];

                    const userSearchQueryItem = { displayName: `"${searchTerm}"`, type: typeAheadTypes.CUSTOM_SEARCH_QUERY };

                    if (this.isEntSearchEnabled) {
                        if (data.partTypes.length) this.curResults = [{ category: this.fcoM('common.partTypes', 'Part Types') }, ...data.partTypes];
                        if (data.vehicles.length)
                            this.curResults = [...this.curResults, { category: this.fcoM('common.vehicles', 'Vehicles') }, ...data.vehicles];
                    } else {
                        this.curResults = [...data.partTypes];
                        const searchTermLower = searchTerm.toLowerCase();
                        const hasExactMatch = !!searchTermLower && this.curResults.some((item) => item.displayName.toLowerCase() === searchTermLower);
                        if (!hasExactMatch) {
                            this.curResults.unshift(userSearchQueryItem);
                        }
                    }

                    this.vehicleRequiredPartsList = data.partTypes.filter((part) => part.applicationPartType).map(({ partTypeId }) => partTypeId);

                    // if quickEntered we want to select right away what the user entered as input
                    if (this.quickEntered) {
                        // if we wait for the results list to render then manually triggering handleSelect method will properly blur
                        // and let the typeahead dropdown close on submit
                        await this.$nextTick();
                        this.handleProductSearchSelect(userSearchQueryItem);

                        // need to reset this in case a new search is started and something like forcecar was ignored by the user
                        this.quickEntered = false;
                    }
                })
                .catch(() => {
                    this.curResults = [];
                });
        },
        // Used when a user types a value and presses enter before results appear or if they press enter and their
        // typed result does not exactly match any results.
        async handlePreProcessingRequest(result) {
            const loading = this.$fcoLoading();
            try {
                const {
                    data: { baseVehicleId, partType, keyword },
                } = await axios.get(this.fcoUrl(`/search/keyword/preprocess?query=${encodeURIComponent(result)}`));

                // scenario 1: has keyword ONLY
                const hasKeywordOnly = Boolean(keyword && !partType && !baseVehicleId);

                // scenario 2: has vehicle ONLY
                const hasVehicleOnly = Boolean(!keyword && !partType && baseVehicleId);

                // scenario 3: part type ONLY
                const hasPartTypeOnly = Boolean(!keyword && partType && !baseVehicleId);

                // scenario 4: has both vehicle and keyword
                const hasVehicleAndKeyword = Boolean(keyword && baseVehicleId);

                // scenario 5: has vehicle and part type
                const hasVehicleAndPartType = Boolean(partType && baseVehicleId);

                if (hasKeywordOnly) {
                    this.handleKeywordSearch(result);
                    loading.remove();
                    return;
                }

                if (hasVehicleOnly) {
                    if (this.productsInCurrentQuote) {
                        this.tempVehicle = baseVehicleId;
                        this.showNewQuotePrompt = true;
                    } else {
                        this.handleVehicleSelect(baseVehicleId);
                    }
                    loading.remove();
                    return;
                }

                if (hasPartTypeOnly) {
                    loading.remove();
                    this.handlePartTypeSelect(partType);
                    return;
                }

                if (hasVehicleAndKeyword) {
                    this.keywordRedirect = keyword;

                    if (this.productsInCurrentQuote) {
                        this.tempVehicle = baseVehicleId;
                        this.showNewQuotePrompt = true;
                    } else {
                        this.handleVehicleSelect(baseVehicleId);
                    }
                    loading.remove();
                    return;
                }

                if (hasVehicleAndPartType) {
                    this.partTypeRedirect = partType;

                    if (this.productsInCurrentQuote) {
                        this.tempVehicle = baseVehicleId;
                        this.showNewQuotePrompt = true;
                    } else {
                        this.handleVehicleSelect(baseVehicleId);
                    }
                    loading.remove();
                    return;
                }
            } catch {
                this.$fcoToast.error(this.fcoM('rs.getparts.partsRequestError', 'Error retrieving product data. Please try again.'));
                loading.remove();
            }
        },
        // this is when we want to take the string result and redirect to an enterprise search plp
        async handleKeywordSearch(searchQuery) {
            const loading = this.$fcoLoading();
            const query = encodeURIComponent(searchQuery);
            let isItemNumberSearch = false;

            try {
                await requestSettled(() => this.requests.getCurrentShop);
                const { data } = await axios.get(this.fcoUrl(`/search/itemNumberMatch?query=${query}&shopId=${this.currentShop.id}`));
                isItemNumberSearch = Boolean(data?.itemNumberMatching);
            } catch (error) {
                // we can fail this silently because we'll send the user to the search-suggestions page as a last resort
            }

            this.startCatalogLookup().finally(() => {
                if (!this.isSPA) {
                    window.location = isItemNumberSearch
                        ? this.fcoUrl(`/search/product/browse.html?query=${query}&itemNumber=true`)
                        : this.fcoUrl(`/search/search.html?query=${query}&retain=false`);
                    return;
                }

                this.$router.push({ path: isItemNumberSearch ? '/search-keyword' : '/search-suggestion', query: { query, itemNumber: true } });
                loading.remove();
            });
        },
        // used for typeahead before phase 2 features and for any phase 2 results that are just what the user typed to determine
        // if they match a result or not and how to handle that situation.
        async handleProductSearchSelect(result) {
            const loading = this.$fcoLoading();
            const searchTerm = this.cleanSearchTerm;
            this.$refs.siteSearch.blur();

            if (this.isEntSearchEnabled) {
                const lowerCaseSearchTerm = searchTerm.toLowerCase();

                // check to see if typed result is matching any part types or vehicles exactly and if so select that instead
                const match = this.curResults.find((curResult) => curResult.displayName?.toLowerCase() === lowerCaseSearchTerm);

                // Check for a substring match (ignoring the exact match case)
                const hasSubstringMatch = this.curResults.some(
                    (item) => item.displayName?.toLowerCase().includes(lowerCaseSearchTerm) && item.displayName?.toLowerCase() !== lowerCaseSearchTerm
                );

                if (match && !hasSubstringMatch) {
                    if (match.type === typeAheadTypes.PART_TYPE) {
                        loading.remove();
                        this.handlePartTypeSelect(match);

                        analytics({
                            event: GTM.Action.SEARCH,
                            data: {
                                accountId: this.currentShop.accountNumber,
                                query: result,
                                usedTypeAhead: true,
                            },
                        });
                    }
                    if (match.type === typeAheadTypes.VEHICLE) {
                        if (this.productsInCurrentQuote) {
                            this.tempVehicle = match.baseVehicleId;
                            this.showNewQuotePrompt = true;
                        } else {
                            this.handleVehicleSelect(match.baseVehicleId);
                        }
                        loading.remove();
                    }
                } else {
                    // Comment this out for now, switching to no preprocessing until a future date
                    // this.handlePreProcessingRequest(searchTerm);
                    this.handleKeywordSearch(searchTerm);

                    analytics({
                        event: GTM.Action.SEARCH,
                        data: {
                            accountId: this.currentShop.accountNumber,
                            query: result,
                            usedTypeAhead: false,
                        },
                    });
                    loading.remove();
                }
                return;
            }

            const isPartTypeResult = result.type === typeAheadTypes.PART_TYPE;
            const isVehicleRequiredForPartType = isPartTypeResult && this.vehicleRequiredPartsList.includes(result.partTypeId);
            const redirect = !this.isSPA
                ? `/search/search.html?query=${encodeURIComponent(isPartTypeResult ? result.displayName : searchTerm)}&retain=false`
                : { path: '/search-suggestion', query: { query: isPartTypeResult ? result.displayName : searchTerm } };

            let analyticsCallback = () => {
                if (!this.isSPA) {
                    window.location = this.fcoUrl(redirect);
                    return;
                }
                this.$router.push(redirect);
                loading.remove();
            };

            if (!this.currentVehicle && isVehicleRequiredForPartType) {
                this.$refs.siteSearch.blur();
                loading.remove();
                this.$store.dispatch('vehicleSelector/forceCar', redirect);

                /*
                 Still send event to analytics that searchbar was used,
                 but let the vehicle selector handle the redirect.
                */
                analyticsCallback = null;
            }

            this.startCatalogLookup().finally(() => {
                analytics({
                    event: GTM.Action.SEARCH,
                    data: {
                        accountId: this.currentShop.accountNumber,
                        query: searchTerm,
                        usedTypeAhead: !isPartTypeResult,
                    },
                    callback: analyticsCallback,
                });
            });
        },
        // used to select a part type and navigate user to part type plp
        async handlePartTypeSelect(partType) {
            const loading = this.$fcoLoading();

            try {
                if (partType.applicationPartType && !this.vehicleIsSelected) {
                    // just need to set this to true since we won't actually redirect to anything and still want logic that checks for URL
                    // to open/highlight and do a force car experience
                    this.$refs.siteSearch.blur();
                    this.$store.dispatch('vehicleSelector/forceCar', true);
                    this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'partTypePendingForceCar', value: true });

                    if (this.isEntSearchEnabled) {
                        this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'saveLookupBeforeRedirect', value: true });
                        this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'partsToSaveBeforeRedirect', value: [partType] });
                    }
                    this.pendingPartType = partType;
                    loading.remove();
                    return;
                }

                const partTypes = [
                    {
                        parentId: partType.parentPartTypeId,
                        partTypeId: partType.partTypeId,
                        partTypeName: partType.displayName,
                        platformId: partType.platform,
                        lookupPartTypId: partType.parentPartTypeId,
                        applicationPartType: partType.applicationPartType,
                    },
                ];

                if (partType.siblings) {
                    partType.siblings.forEach((sibling) => {
                        partTypes.push({
                            parentId: sibling.parentPartTypeId,
                            partTypeId: sibling.partTypeId,
                            partTypeName: sibling.displayName,
                            platformId: sibling.platform,
                            lookupPartTypId: sibling.parentPartTypeId,
                            applicationPartType: partType.applicationPartType,
                        });
                    });
                }

                await clearAndSelectPartType(partTypes);

                if (this.isEntSearchEnabled) {
                    // get typeahead part types into a more friendly format for PLP retain and product requests
                    const partsList = partTypes.map((pt) => ({
                        description: pt.description || pt.partTypeName,
                        parentPartTypeId: pt.parentPartTypeId || pt.parentId,
                        partTypeId: pt.partTypeId,
                        platformId: pt.platformId || pt.platform,
                        applicationPartType: pt.applicationPartType,
                    }));

                    this.$_partTypesMixin_getResolvedLeaf(partsList, LookupOrigin.TYPEAHEAD);
                }

                if (!this.isSPA) {
                    window.location.href =
                        !this.isEntSearchEnabled || partType.kits
                            ? this.fcoUrl(`/parttype/mini/getparts.html?query=${encodeURIComponent(partType.displayName)}`)
                            : this.fcoUrl('/parttype/mini/v2/getParts.html');
                    return;
                }

                loading.remove();

                this.$router.push({
                    path: this.getSpaCatalogRedirectPath(Boolean(partType.kits)),
                    query: this.isEntSearchEnabled ? { query: partType.displayName } : undefined,
                });
            } catch {
                this.$fcoToast.error(this.fcoM('rs.getparts.partsRequestError', 'Error retrieving product data. Please try again.'));
                loading.remove();
            }
        },
        // used for handling a result that is selecting a vehicle for the user
        async handleVehicleSelect(baseVehicleId, keepQuote = false) {
            const loading = this.$fcoLoading();
            const vehicleData = {
                baseVehicleId,
                quote: keepQuote,
            };
            const {
                data: { vehicle: fullVehicleData },
            } = await axios.post(this.fcoUrl('/vehicle/select/v2/'), vehicleData);

            // if keywordRedirect has a value we know to do that instead of continue on. This could be from a vehicle
            // prompt or from a preprocessing request that has both a vehicle and keyword search to be handled together.
            if (this.keywordRedirect) {
                await this.$store.commit('vehicleSelector/setCurrentVehicle', fullVehicleData);
                this.handleKeywordSearch(this.keywordRedirect);
                loading.remove();
                return;
            }

            // if partTypeRedirect has a value we know to do that instead of continue on. This could be from a vehicle
            // prompt or from a preprocessing request that has both a vehicle and part type lookup to be handled together.
            if (this.partTypeRedirect) {
                await this.$store.commit('vehicleSelector/setCurrentVehicle', fullVehicleData);
                this.handlePartTypeSelect(this.partTypeRedirect);
                return;
            }

            this.handleVehicleSelectSuccess({ gaEventAction: 'typeahead', vehicle: fullVehicleData });
            if (this.preventNavigationOnVehicleUpdate) {
                if (this.showNewQuotePrompt) this.showNewQuotePrompt = false;
                loading.remove();
            }
        },
        // starting point method for all typeahead selections
        handleSelect(result) {
            // clear out the input field when a selection is made
            this.inputValue = '';
            this.$refs.siteSearch.blur();

            if (this.isEntSearchEnabled) {
                if (result.type === typeAheadTypes.VEHICLE) {
                    if (this.productsInCurrentQuote) {
                        this.tempVehicle = result.baseVehicleId;
                        this.showNewQuotePrompt = true;
                    } else {
                        this.handleVehicleSelect(result.baseVehicleId);
                    }

                    // track autocomplete signal when a vehicle is manually selected by the user
                    this.trackAutoCompleteSignal(this.curVehicleResults, result);
                } else if (result.type === typeAheadTypes.PART_TYPE) {
                    this.handlePartTypeSelect(result);

                    analytics({
                        event: GTM.Action.SEARCH,
                        data: {
                            accountId: this.currentShop.accountNumber,
                            query: result.displayName,
                            usedTypeAhead: true,
                        },
                    });

                    // track autocomplete signal when a part type is manually selected by the user
                    this.trackAutoCompleteSignal(this.curPartsResults, result);
                } else {
                    this.handleProductSearchSelect(result);
                }
            } else {
                this.handleProductSearchSelect(result);
            }
        },
        trackAutoCompleteSignal(resultList, { type, displayName, partTypeId }) {
            this.$_signalsMixin_trackAutoCompleteSelection({
                selectedSuggestion: {
                    suggestionType: type,
                    basis: this.cleanSearchTerm,
                    partTypeName: displayName,
                    partTypeId,
                },
                position: resultList.findIndex((item) => item.displayName === displayName),
            });
        },
        cleanTerm(term) {
            return term.replace(/"|<|>/g, '');
        },
        ...mapActions('usageTracking', ['startCatalogLookup']),
    },
    watch: {
        partTypePendingForceCar(val) {
            // this should only update to false if the vsMixin vehicle select success is called with a pending part type
            if (!val && this.pendingPartType) {
                this.handlePartTypeSelect(this.pendingPartType);
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('requestIfIdle', ['getFeatures', 'getCurrentShop', 'vehicleSelector/getCurrentVehicle']);
    },
};
</script>

<style lang="scss">
@import '~scssVariables/mixins';
@import '~scssVariables/config';

.smart-product-search-container {
    height: 41px;

    .fco-dropdown,
    .fco-dropdown-toggle,
    input {
        height: 100%;
    }
}

.main-search-results {
    a {
        text-decoration: none;
        font-size: 0.875em;
        padding-left: $padding;
    }

    .active {
        background-color: $green;
    }

    .spinner {
        display: none;
    }

    &.fco-dropdown-menu {
        max-height: 1000px;
    }
}
</style>
